import 'jquery';

export default class Navigation {
  constructor() {
    this.element = $('.c-navigation-open');
    if (this.element.length > 0) {
      this.element.on('click', (event) => {
        $('.c-nav-fullscreen').addClass('open');
        $('.c-nav-fullscreen').removeClass('closed');
      });
      $('.c-nav-fullscreen .c-close-button').on('click', (event) => {
        $('.c-nav-fullscreen').removeClass('open');
        $('.c-nav-fullscreen').addClass('closed');
      });
      $('.c-nav-fullscreen .has-sub-menu > a').on('click', (event) => {
        // todo: now only for 1 submenu per menu.
        $('.c-nav-fullscreen .has-sub-menu').toggleClass('is-active');
      })
    }
  }
}
