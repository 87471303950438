import 'jquery';

export default class Overview {
  constructor() {
    this.element = $('.c-overview-open');
    if (this.element.length > 0) {
      this.element.on('click', (event) => {
        $('.c-overview-fullscreen').addClass('open');
        $('.c-overview-fullscreen').removeClass('closed');
      });
      $('.c-overview-fullscreen .c-close-button').on('click', (event) => {
        $('.c-overview-fullscreen').removeClass('open');
        $('.c-overview-fullscreen').addClass('closed');
      });
    }
  }
}
