import 'jquery';
import {gsap} from "gsap";

export default class LightPaper {
  constructor() {
    this.element = $('.c-light-paper');
    this.items = this.element.find('.c-light-paper__wrapper__item');
    if (this.element.length > 0 && this.items.length > 0) {
      this.index = 0;
      gsap.delayedCall(1, this.play.bind(this));
      this.element.find('.c-close-button').on('click', this.removeIt.bind(this));
    } else if (this.element) {
      this.element.remove();
    }
  }

  play() {
    this.animateIn();
  }

  animateIn() {
    gsap.to(this.items.get(this.index), {alpha: 1, y: 0});
    if (this.items.length > 1) {
      gsap.delayedCall(5, this.animateOut.bind(this));
    }
  }

  animateOut() {
    gsap.to(this.items.get(this.index), {
      alpha: 0, y: '-2rem', callbackScope: this, onComplete: function () {
        gsap.set(this.items.get(this.index), {y: '2rem'});
        this.index++;
        if (this.index >= this.items.length) {
          this.index = 0;
        }
        this.animateIn();
      }
    });
  }

  removeIt() {
    this.element.remove();
  }
}
