import 'jquery';
import lozad from 'lozad';
import LightPaper from "./components/LightPaper";
import SectionLanding from "./components/SectionLanding";
import SectionHero from "./components/SectionHero";
import Navigation from "./components/Navigation";
import Overview from "./components/Overview";
import Slider from "./components/Slider";
import Preloader from "./components/Preloader";
import Faq from "./components/Faq";
import ArrowScroll from "./components/ArrowScroll";

$(document).ready(() => {
  new Navigation();
  new Overview();
  new SectionHero();
  new Faq();
  new ArrowScroll();
});

$(window).on('load', () => {
  new Preloader();
  new LightPaper();
  new SectionLanding();
  new Slider();
  const observer = lozad();
  observer.observe();
  if (typeof htmx !== 'undefined') {
    htmx.on('htmx:afterSwap', (event) => {
      observer.observe();
    });
  }
})
