import 'jquery';
import {gsap} from "gsap";

export default class SectionHero {
  constructor() {
    this.element = $('.c-section-hero');
    if (this.element.length > 0) {
      this.play();
    }
  }

  play() {
    gsap.to($(this.element.find('.c-section-hero__left')[0]), {alpha: 1, y:0, duration: 0.7});
    gsap.to($(this.element.find('.c-section-hero__right')[0]), {delay: 0.2, alpha: 1, y:0, duration: 0.7});
    gsap.to($(this.element.find('.c-section-hero__left .c-arrow')[0]), { delay: 1, alpha: 1, duration: 0.5});
  }
}
