import 'jquery';

export default class Faq {
  constructor() {
    this.element = $('.c-faq');
    if (this.element.length > 0) {
      $('.c-faq .c-faq__header').on('click', (event) => {
        $(event.currentTarget).parent().toggleClass('is-active');
      })
    }
  }
}
