import 'jquery';
import {gsap} from "gsap";

export default class SectionLanding {
  constructor() {
    this.element = $('.c-section-landing');
    if (this.element.length > 0) {
      this.splitText = this.split(this.element.find('h1')[0]);
      this.chars = $(this.splitText).find('.SplitTextJS-char');
      this.cursor = $(this.element.find('.title__cursor'));
      gsap.fromTo(this.cursor, { alpha: 0 }, { alpha: 1, repeat: -1, duration: 0.5, yoyo: true});
      gsap.delayedCall(0.5, this.play.bind(this));
    }
  }

  play() {
    gsap.set($(this.element.find('h1')[0]), {alpha: 1});
    let _index = 0;
    this.chars.each((index, element) => {
      const offset = $(element).position();
      const width = $(element).width();
      gsap.set(this.cursor, {delay: (index) * 0.09, left:offset.left + width,  top:offset.top});
      gsap.set($(element), {delay: (index) * 0.09, alpha: 1});
      _index = index;
    })
    gsap.to($(this.element.find('p')[0]), { delay: _index * 0.09 + 0.5, alpha: 1, y:0, duration: 0.7});
    gsap.to($(this.element.find('.c-arrow')[0]), { delay: _index * 0.09 + 1.5, alpha: 1, duration: 0.5});
    gsap.set(this.cursor, { delay: _index * 0.09 + 3, display: "none"});
  }

  placeCursor() {
  }

  createSpan(_class) {
    let span = document.createElement('span');
    span.style.display = "inline-block";
    span.className = _class;
    return span;
  }

  split(_target) {
    let containerArray = new Array;
    const splittedTarget = _target.innerText.split(' ')
    let counter = splittedTarget.length
    splittedTarget.map(word => {
      const wrapper = this.createSpan('SplitTextJS-wrapper')
      word.split(/(?!^)/).map(char => {
        let el = this.createSpan('SplitTextJS-char')
        el.innerText = char
        wrapper.appendChild(el)
      })
      counter--
      containerArray.push(wrapper)
      if (counter > 0) {
        let space = this.createSpan('SplitTextJS-char SplitTextJS-spacer')
        space.innerHTML = '&nbsp;'
        containerArray.push(space)
      }
    })
    _target.innerHTML = ''
    containerArray.forEach(child => {
      _target.appendChild(child)
    })
    return _target
  }
}
