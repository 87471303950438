import 'jquery';

export default class ArrowScroll {
  constructor() {
    this.element = $('.c-arrow--scroll');
    if (this.element.length > 0) {
      this.element.on('click', (event) => {
        window.scrollTo({top: $(window).height(), left:0, behavior: 'smooth'});
      });
    }
  }
}
