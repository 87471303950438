import 'jquery';
import 'slick-carousel';

export default class Slider {
  constructor() {
    this.element = $('.slider');
    if (this.element.length > 0) {
      $('.slider').slick({
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        autoplaySpeed: 5000,
        speed: 750,
      });
    }
    this.element = $('.slider-mini');
    if (this.element.length > 0) {
      $('.slider-mini').slick({
        infinite: false,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: false,
        dots: true,
        autoplay: true,
        pauseOnHover: false,
        pauseOnFocus: false,
        autoplaySpeed: 5000,
        speed: 750,
      });
    }
  }
}
