import 'jquery';
import {gsap} from "gsap";

export default class Preloader {
  constructor() {
    this.element = $('.o-preloader');
    if (this.element.length > 0) {
      gsap.to(this.element, { autoAlpha: 0});
    }
  }
}
